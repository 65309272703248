import { computed, makeAutoObservable, runInAction } from 'mobx';
import ApiClient from '../ApiClient';
import moment from 'moment';
import { RequestActionTaken } from '../components/pages/cases/CaseDetailsPage';

export type AlertLevels = 'OK' | 'DELAYED' | 'WARNING';
export type HelpRequestStatus =
  | 'COMPLETE'
  | 'INCOMPLETE'
  | 'REOPENED'
  | 'HANDED_TO_THIRD_PARTY';
export type CreationType = 'USER_ID' | 'MOBILE' | 'INCIDENT' | 'AUTOMATIC';
export type RequestSources =
  | 'INCIDENT_APP'
  | 'MOBILE'
  | 'FACIAL_ID'
  | 'QR_WEBSITE'
  | 'KIOSK'
  | 'THREE_HOUR_GAMBLING'
  | 'COMMAND_CENTRE'
  | 'GENERAL_API'
  | 'RESPONDER_APP';

export type UserHelpRequests = {
  attachments: any[];
  helpRequestId: number;
  creationType: CreationType;
  content: string;
  customActions: any[];
  incidentReasons: any[];
  incidentReason: any;
  actionStatus: HelpRequestStatus;
  requestSource: RequestSources;
  actingFromSource: RequestSources;
  requesterName?: string;
  siteLocation?: string;
  machineNumber?: string;
  personDescription?: string;
  notes: any[];
  requestInitiatedTime: number;
  timeCompleted: number;
  timeCreated: number;

  actingStaffMemberName: string;
  requesterFirstName?: string;
  requesterLastName?: string;
  requesterMobileNumber?: string;
  requesterEmailAddress?: string;
  requesterHelpKind?: string;
  requesterMemberNumber?: string;
  requesterS3ImageFileName?: string;

  requesterCaseCount: number;

  // local values
  secondsSince: number;
  alertLevel?: AlertLevels;
};

export type HelpRequestActionLogItem = {
  requestActionLogId: number;
  helpRequestId: number;

  actionType: string;
  actionStatus: RequestActionTaken;
  actionNote: string;
  actionStatusDisplayText: string;

  actionDisplayText: string;

  timeCreated: number;
};

export type AlertReceivers = {
  alertReceiverId?: number;

  firstName: string;
  lastName: string;
  phoneNumber: string;
  emailAddress: string;
};

export type StaffResponderAccounts = {
  venueStaffUserId?: number;

  firstName: string;
  lastName: string;
  phoneNumber: string;

  userHasSetup: boolean;
};

export type StaffUserAccounts = {
  venueStaffUserId?: number;

  firstName: string;
  lastName: string;
  phoneNumber: string;

  userHasSetup: boolean;
};

export type StringSettingType = {
  venueStringSettingId?: number;
  venueId?: number;
  stringContent?: string;
  listType?: string;
  timeCreated?: number;
  category?: string;
};

export type SiteLocations = {
  id?: number;

  location: string;
};

export type HelpRequestActions = 'CLOSE_CASE' | 'OPEN_CASE';

export type DataSummaryType = {
  averageResponseTimeSecs: number;
  previousDayRequests: UserHelpRequests[];
};

export default class SummaryState {
  todaysRequestsCount: number = 0;
  previousDayRequestsCount: number = 0;
  last28DaysRequestsCount: number = 0;

  IncidentReasonCategory: any;
  averageResponseTimeSecs?: number = undefined;

  venueClosingTime: number | null = null;
  caseSearchFilterTime: number | null = null;

  devicesCount?: number = undefined;
  loggedInDevicesCount: number = 0;

  todaysRequests: UserHelpRequests[] = [];
  previousDayRequests: UserHelpRequests[] = [];
  last28DaysRequests: UserHelpRequests[] = [];

  alertReceivers: AlertReceivers[] = [];
  staffResponderAccounts: StaffResponderAccounts[] = [];
  staffUserAccounts: StaffUserAccounts[] = [];

  incidentReasons: StringSettingType[] = [];
  customActions: StringSettingType[] = [];
  siteLocations: StringSettingType[] = [];

  searchCaseResults: UserHelpRequests[] = [];
  caseSearchTerm = '';

  dataLoaded = false;

  private pollingTimer: NodeJS.Timeout | undefined;

  constructor() {
    makeAutoObservable(this);

    // can move this into the individual component later on rather thann having a global interval
    setInterval(() => {
      let currentTime = moment().valueOf();

      runInAction(() => {
        this.previousDayRequests.forEach((request) => {
          request.secondsSince = Math.floor(
            (currentTime - request.requestInitiatedTime) / 1000
          );

          let minsSince = Math.floor(request.secondsSince / 60);
          if (minsSince <= 5) {
            request.alertLevel = 'OK';
          } else if (minsSince <= 10) {
            request.alertLevel = 'DELAYED';
          } else {
            request.alertLevel = 'WARNING';
          }
        });

        this.todaysRequests.forEach((request) => {
          request.secondsSince = Math.floor(
            (currentTime - request.requestInitiatedTime) / 1000
          );

          let minsSince = Math.floor(request.secondsSince / 60);
          if (minsSince <= 5) {
            request.alertLevel = 'OK';
          } else if (minsSince <= 10) {
            request.alertLevel = 'DELAYED';
          } else {
            request.alertLevel = 'WARNING';
          }
        });
      });
    }, 1000);
  }

  resetCaseSearch() {
    this.caseSearchTerm = '';
    this.searchCaseResults = [];
  }

  deleteStaffAccount(venueStaffUserId: number) {
    this.staffResponderAccounts = this.staffResponderAccounts.filter(
      (user) => user.venueStaffUserId !== venueStaffUserId
    );
  }

  deleteIncidentReason(id: number) {
    this.incidentReasons = this.incidentReasons.filter(
      (user) => user.venueStringSettingId !== id
    );
  }

  deleteCustomAction(id: number) {
    this.customActions = this.customActions.filter(
      (user) => user.venueStringSettingId !== id
    );
  }

  deleteSiteLocation(id: number) {
    this.siteLocations = this.siteLocations.filter(
      (user) => user.venueStringSettingId !== id
    );
  }

  deleteAlertReceiver(alertReceiverId: number) {
    this.alertReceivers = this.alertReceivers.filter(
      (receiver) => receiver.alertReceiverId !== alertReceiverId
    );
  }

  startHomeDataPolling = () => {
    if (this.pollingTimer != undefined) return;

    this.pollingTimer = setInterval(() => {
      this.refreshHomeData();
    }, 10000);
  };

  @computed get getOpenCaseCount() {
    return this.last28DaysRequests.filter(
      (req) =>
        req.actionStatus === 'INCOMPLETE' || req.actionStatus === 'REOPENED'
    ).length;
  }

  changeVenue = () => {
    this.dataLoaded = false;

    this.averageResponseTimeSecs = undefined;

    this.venueClosingTime = null;
    this.caseSearchFilterTime = null;

    this.devicesCount = undefined;
    this.loggedInDevicesCount = 0;

    this.todaysRequests = [];
    this.previousDayRequests = [];
    this.last28DaysRequests = [];

    this.alertReceivers = [];
    this.staffResponderAccounts = [];
    this.staffUserAccounts = [];

    this.searchCaseResults = [];
    this.caseSearchTerm = '';

    this.refreshHomeData();
  };

  refreshHomeData() {
    ApiClient.getHomeSummary()
      .then((response: any) => {
        runInAction(() => {
          this.todaysRequestsCount = response.data.todaysRequestsCount;
          this.previousDayRequestsCount =
            response.data.previousDayRequestsCount;
          this.last28DaysRequestsCount = response.data.last28DaysRequestsCount;

          this.IncidentReasonCategory = response.data.IncidentReasonCategory;
          this.averageResponseTimeSecs = response.data.averageResponseTimeSecs;
          this.todaysRequests = response.data.todaysRequests ?? [];
          this.previousDayRequests = response.data.previousDayRequests ?? [];
          this.alertReceivers = response.data.alertReceivers;
          this.staffResponderAccounts = response.data.staffMemberAccounts;
          this.staffUserAccounts = response.data.staffUserAccounts;
          this.incidentReasons = response.data.features?.incidentReasons;
          this.customActions = response.data.features?.customActions;
          this.siteLocations = response.data.features?.siteLocations;
          this.devicesCount = response.data.devicesCount;
          this.loggedInDevicesCount = response.data.loggedInDevicesCount;
          this.venueClosingTime = response.data.venueClosingTime;
          this.caseSearchFilterTime = response.data.caseSearchFilterTime;
          this.last28DaysRequests = response.data.last28DaysRequests ?? [];

          this.previousDayRequests = this.previousDayRequests.map((request) => {
            request.secondsSince = Math.floor(
              (moment().valueOf() - request.requestInitiatedTime) / 1000
            );

            let minsSince = Math.floor(request.secondsSince / 60);
            if (minsSince <= 5) {
              request.alertLevel = 'OK';
            } else if (minsSince <= 10) {
              request.alertLevel = 'DELAYED';
            } else {
              request.alertLevel = 'WARNING';
            }

            return request;
          });

          this.todaysRequests = this.todaysRequests.map((request) => {
            request.secondsSince = Math.floor(
              (moment().valueOf() - request.requestInitiatedTime) / 1000
            );

            let minsSince = Math.floor(request.secondsSince / 60);
            if (minsSince <= 5) {
              request.alertLevel = 'OK';
            } else if (minsSince <= 10) {
              request.alertLevel = 'DELAYED';
            } else {
              request.alertLevel = 'WARNING';
            }

            return request;
          });

          this.last28DaysRequests = this.last28DaysRequests.map((request) => {
            request.secondsSince = Math.floor(
              (moment().valueOf() - request.requestInitiatedTime) / 1000
            );

            let minsSince = Math.floor(request.secondsSince / 60);
            if (minsSince <= 5) {
              request.alertLevel = 'OK';
            } else if (minsSince <= 10) {
              request.alertLevel = 'DELAYED';
            } else {
              request.alertLevel = 'WARNING';
            }

            return request;
          });

          this.dataLoaded = true;
        });
      })
      .catch((error) => {});
  }
}

export const summaryState = new SummaryState();
